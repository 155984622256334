import React from 'react'
import CampaignLog from '../../../components/Email/CampaignLogs'
import CampaignLogEntry from '../../../components/Email/CampaignLogEntry'
import FSRoute from '../../../components/FSRoute'
import { isBrowser } from '../../../utils/misc'
const App = ({ data }) => {
  if (!isBrowser) return null
  return (
    <>
      <FSRoute
        path="/email/campaign/log"
        component={CampaignLog}
        fsOnly="false"
        data={data}
      />
      <FSRoute
        path="/email/campaign/log/:logid"
        component={CampaignLogEntry}
        fsOnly="false"
        data={data}
      />
    </>
  )
}

export default App
