import React from 'react'
import Layout from '../Layout'
import View from '../View'
import EmailPreview from '../EmailPreview'
import  Moment from 'moment'
import { getCurrentUser } from '../../utils/auth'
import { getCampaignLogById, getLogsByCampaignId } from '../../utils/email'
import { Link } from 'gatsby'
import { Table } from '../Table'
import WaitLoader from '../WaitLoader'

class EmailCampaignLog extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      to: 'example@forms-surfaces.net',
      log: {},
      logs: [],
      user: {},
      totalDelivered: 0,
      totalFailed: 0,
      uniqueOpens: 0,
      totalClicks: 0,
      errorMessage: '',
      loading: true
    }
  }

  componentDidMount() {
    var user = getCurrentUser()
    this.setState({
      user: user
    })
    getCampaignLogById(this.props.logid, function(err, response){
      if(err){
        this.setState({errorMessage: err.message})
      }
      if(response){
        response.logs.forEach((item, i) => {
          item.clicked = item.events.filter(event => event.eventType === 'clicked').length
          if(item.events.find(event => event.eventType === 'delivered')){
            item.status = "Delivered"
          }
          if(item.events.find(event => event.eventType === 'failed')){
            item.status = "Failed"
          }
          let opened = item.events.filter(event => event.eventType === 'opened')
          if(opened.length){
            item.opened = Moment(opened[0].timeStamp)
            .local()
            .format("MM-DD-YYYY hh:mm:ss a")
          }
        });
        let totalDelivered = response.events.filter(event => event.eventType === 'delivered').length
        let totalFailed = response.events.filter(event => event.eventType === 'failed').length
        let totalClicks = response.events.filter(event => event.eventType === 'clicked').length
        let distinctOpens = []
        response.events.filter(event => event.eventType === 'opened').forEach((open,i)=>{
          if(!distinctOpens.find(distincto => distincto.recipient === open.recipient)){
            distinctOpens.push(open)
          }
        })
        this.setState({
          log: response,
          logs: response.logs,
          totalDelivered: totalDelivered,
          totalFailed: totalFailed,
          totalClicks: totalClicks,
          uniqueOpens: distinctOpens.length,
          loading: false}, function(){
            console.log(this.state)
          })
      }else{
        this.setState({errorMessage: 'Log not found'})
      }
    }.bind(this))
  }

  render(){
    const logColumns = [
      {
        Header: 'To Address',
        accessor: 'to'
      },
      {
        id: 'status',
        Header: 'Status',
        accessor: 'status'
      },
      {
        id: 'clicked',
        Header: 'Clicked',
        accessor: 'clicked'
      },
      {
        id: 'opened',
        Header: 'Opened',
        accessor: 'opened'
      },
      {
        id: 'actions',
        Header: 'Actions',
        accessor: d => d._id,
        Cell: props => <div>
          <Link className="button" to={'/email/log/' + props.value} style={{'textAlign':'center'}}>
          View Email Logs
          </Link>
          </div>
      }
    ]
    let errorMessage = ''
    if(this.state.errorMessage){
      errorMessage = <p className="error">Error: {this.state.errorMessage}</p>
    }
    let preview = ''
    let details = ''
    let details2 = ''

    if(this.state.log){
      if(this.state.log.subject){
        details = <ul>
                  <li>Campaign Name: {this.state.log.name}</li>
                  <li>Subject: {this.state.log.subject}</li>
                  <li>Sent: {Moment(this.state.log.sent)
                  .local()
                  .format("MM-DD-YYYY hh:mm:ss a")}</li>
                </ul>

        details2 = <ul>
                <li>Delivered: {this.state.totalDelivered}</li>
                <li>Failed: {this.state.totalFailed}</li>
                <li>Opened: {this.state.uniqueOpens}</li>
                <li>Clicks: {this.state.totalClicks}</li>
                </ul>

        preview = <div className="col-xs-12 col-sm-12 col-md-12">
                  <h3>Preview:</h3>
                  <EmailPreview
                    to={this.state.to}
                    subject={this.state.subject}
                    userMessage={this.state.log.userMessage}
                    showUserImage={this.state.log.showUserImage}
                    domain={this.state.log.domain}
                    pages={this.state.log.pagesShared}
                  />
                  </div>
      }
    }
    let parent = {
      url: '/email/campaign/log',
      title: 'EMAIL CAMPAIGNS SENT'
    }
    if(!this.state.log.sent){
      parent = {
        url: '/email/campaign/scheduled',
        title: 'EMAIL CAMPAIGNS SCHEDULED'
      }
    }
    return (
      <Layout>
      <View title="EMAIL CAMPAIGN LOG DETAILS" parent={parent}>
        <div className="row relative">
        <WaitLoader loading={this.state.loading} />
        <div className="col-xs-12 col-sm-12 col-md-6">
        <h3>Details</h3>
          {details}
        </div>
        <div className="col-xs-12 col-sm-12 col-md-12">
        <h3>Email Logs</h3>
        <Table
          data={this.state.logs}
          columns={logColumns}
          defaultPageSize={10}
          minRows={3}
        />
        </div>
        {errorMessage}
        {preview}
        </div>
      </View>
      </Layout>
    )
  }

}

export default EmailCampaignLog
